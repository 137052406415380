//
// _.scss
//

.restaurant-css {
    body,
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,
    #topnav {
        font-family: $font-noto !important;
    }
}